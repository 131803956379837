import {PrimeReactProvider} from 'primereact/api';
import {Panel} from 'primereact/panel';
import {Button} from 'primereact/button';
import {InputNumber} from 'primereact/inputnumber';
import {TabMenu} from 'primereact/tabmenu';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {RadioButton} from 'primereact/radiobutton';
import {Tooltip} from 'primereact/tooltip';
import {Dialog} from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import {useState} from "react";
import toRupiah from '@develoka/angka-rupiah-js';
import HargaModalCard from "./HargaModalCard";

const renderMarketplaceTab = (item, itemIndex) => (
    <a className="p-menuitem-link flex align-items-center gap-2 pt-0 pb-2 md:pb-3 md:pt-1">
        <img alt={item.name} src={item.image} style={{ width: '32px' }} />
        <span className="font-bold">{item.name}</span>
    </a>
);

const marketplaces = [
    {
        name: 'Shopee',
        image: '/images/shopee.png',
        template: (item) => renderMarketplaceTab(item, 0),
    },
    {
        name: 'Tokopedia (soon)',
        image: '/images/tokopedia.png',
        template: (item) => renderMarketplaceTab(item, 1),
        disabled: true
    },
];

function App() {
    const [useVoucher, setUseVoucher] = useState(false);
    const [useOngkirXtra, setUseOngkirXtra] = useState(false);
    const [useCBXtra, setUseCBXtra] = useState(false);
    const [useAsuransi, setUseAsuransi] = useState(false);
    const [sellerType, setSellerType] = useState(2);
    const [category, setCategory] = useState('A');
    const [modalPrice, setModalPrice] = useState('');
    const [sellPrice, setSellPrice] = useState('');
    const [discountType, setDiscountType] = useState('percentage');
    const [voucherType, setVoucherType] = useState('percentage');
    const [voucherValue, setVoucherValue] = useState('');
    const [voucherAmount, setVoucherAmount] = useState(0);
    const [maxVoucherOffType, setMaxVoucherOffType] = useState('0');
    const [maxVoucherOff, setMaxVoucherOff] = useState('');
    const [minVoucherUsage, setMinVoucherUsage] = useState('');
    const [feePercentage, setFeePercentage] = useState(0);
    const [ongkirXtraFee, setOngkirXtraFee] = useState(0);
    const [showResultModal, setShowResultModal] = useState(false);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [discountValue, setDiscountValue] = useState('');
    const [finalSellPrice, setFinalSellPrice] = useState(0);
    const cbXTRAFee = 1.4;
    const asuransiFee = 0.5;

    const calculate = async () => {
        const feeMapping = {
            0: {
                'A': 0,
                'B': 0,
                'C': 0,
                'D': 0,
            },
            1: {
                'A': 8,
                'B': 7.5,
                'C': 5.75,
                'D': 4.25,
            },
            2: {
                'A': 8,
                'B': 7.5,
                'C': 5.75,
                'D': 4.25,
            },
            3: {
                'A': 8.5,
                'B': 6,
                'C': 4,
                'D': 2.5,
            }
        }

        const ongkirFee = category === 'D' ? 0 : 4;
        setOngkirXtraFee(ongkirFee);

        const feePercent = feeMapping[sellerType][category];
        setFeePercentage(feePercent);

        let discountAmountTemp = 0;

        if (discountValue) {
            if (discountType === 'percentage') {
                discountAmountTemp = (discountValue / 100 * sellPrice);
            } else {
                discountAmountTemp = discountValue;
            }

            setDiscountAmount(discountAmountTemp);
        }

        let voucherAmountTemp = 0;
        if (useVoucher && voucherValue) {
            if (voucherType === 'percentage') {
                voucherAmountTemp = (voucherValue / 100 * (sellPrice - discountAmountTemp))
            } else {
                voucherAmountTemp = voucherValue;
            }

            if (maxVoucherOffType === '1' && maxVoucherOff) {
                voucherAmountTemp = voucherAmountTemp > maxVoucherOff ? maxVoucherOff : voucherAmountTemp;
            }

            //her
            if (minVoucherUsage && (sellPrice - discountAmountTemp - voucherAmountTemp) < minVoucherUsage) {
                voucherAmountTemp = 0;
            }

            setVoucherAmount(voucherAmountTemp)
        }

        let basicTotal = sellPrice - discountAmountTemp - voucherAmountTemp;
        let feeAmount = feePercent / 100 * basicTotal;
        let ongkirFeeAmount = 0;
        if (useOngkirXtra) {
            ongkirFeeAmount = (ongkirFee / 100 * basicTotal) > 10000 ? 10000 : ongkirFee / 100 * basicTotal
        }
        let cashbackXtraFeeAmount = 0;
        if (useCBXtra) {
            cashbackXtraFeeAmount = (cbXTRAFee / 100 * basicTotal) > 10000 ? 10000 : cbXTRAFee / 100 * basicTotal;
        }

        let asuransiAmount = 0;
        if (useAsuransi) {
            asuransiAmount = asuransiFee / 100 * basicTotal;
        }

        setFinalSellPrice(basicTotal - feeAmount- ongkirFeeAmount - cashbackXtraFeeAmount - asuransiAmount)

        setShowResultModal(true);
    }

    return (
        <PrimeReactProvider>
            <main>
                <Dialog header="Hasil Perhitungan" visible={showResultModal}
                        onHide={() => setShowResultModal(!showResultModal)}
                        style={{width: '50vw'}} breakpoints={{'960px': '75vw', '641px': '100vw'}}>
                    <div>
                        <h3 className="my-1">Harga Modal</h3>

                        <div>
                            {toRupiah(modalPrice || 0, {formal: false, floatingPoint: 0})}
                        </div>
                    </div>
                    <Divider/>

                    <div>
                        <h3 className="my-1">Harga Jual</h3>

                        <div>
                            {
                                (discountValue) ? (
                                    <span className="mr-2 ">
                                        {toRupiah(sellPrice - discountAmount, {formal: false, floatingPoint: 0})}
                                    </span>
                                ) : ""
                            }
                            <span className={discountValue ? 'text-red-500' : ''}
                                  style={{textDecoration: discountValue ? "line-through" : "none"}}>
                                {toRupiah(sellPrice || 0, {formal: false, floatingPoint: 0})}
                            </span>
                        </div>
                    </div>
                    <Divider/>

                    <div>
                        <h3 className="my-1">Potongan Marketplace & Voucher</h3>

                        {
                            (useVoucher && voucherAmount) ? (
                                <div className="text-red-500">
                                    {
                                        `- ${toRupiah(voucherAmount, {
                                            formal: false,
                                            floatingPoint: 0
                                        })} (Voucher ditanggung penjual)`
                                    }
                                </div>
                            ) : ""
                        }

                        {
                            feePercentage && (
                                <div className="text-red-500">
                                    {
                                        `- ${toRupiah(feePercentage / 100 * (sellPrice - discountAmount - voucherAmount), {
                                            formal: false,
                                            floatingPoint: 0
                                        })} (Biaya Layanan ${feePercentage}%)`
                                    }
                                </div>
                            )
                        }
                        {
                            useOngkirXtra && (
                                <div className="text-red-500">
                                    {
                                        `- ${toRupiah(ongkirXtraFee ? ((ongkirXtraFee / 100 * (sellPrice - discountAmount - voucherAmount)) > 10000 ? 10000 : ongkirXtraFee / 100 * (sellPrice - discountAmount - voucherAmount)) : '0', {
                                            formal: false,
                                            floatingPoint: 0
                                        })} (Gratis Ongkir XTRA ${ongkirXtraFee ? `${ongkirXtraFee}%` : '- Gratis'})`
                                    }
                                </div>
                            )
                        }
                        {
                            useCBXtra && (
                                <div className="text-red-500">
                                    {
                                        `- ${toRupiah((cbXTRAFee / 100 * (sellPrice - discountAmount - voucherAmount)) > 10000 ? 10000 : cbXTRAFee / 100 * (sellPrice - discountAmount - voucherAmount), {
                                            formal: false,
                                            floatingPoint: 0
                                        })} (Cashback XTRA ${cbXTRAFee}%)`
                                    }
                                </div>
                            )
                        }

                        {
                            useAsuransi && (
                                <div className="text-red-500">
                                    {
                                        `- ${toRupiah((asuransiFee / 100 * (sellPrice - discountAmount - voucherAmount)) > 10000 ? 10000 : asuransiFee / 100 * (sellPrice - discountAmount - voucherAmount), {
                                            formal: false,
                                            floatingPoint: 0
                                        })} (Asuransi ${asuransiFee}%)`
                                    }
                                </div>
                            )
                        }
                    </div>
                    <Divider />

                    <div>
                        <h3 className="my-1">Keuntungan Bersih</h3>

                        <div className="flex align-items-center mb-2">
                            <div>
                                <div>
                                    <b>Total Penghasilan</b>
                                </div>
                                <div>
                                    {toRupiah(finalSellPrice, {
                                        formal: false,
                                        floatingPoint: 0
                                    })}
                                </div>
                            </div>
                            <div className="px-3">
                                -
                            </div>
                            <div>
                                <div>
                                    <b>Harga Modal</b>
                                </div>
                                <div>
                                    {toRupiah(modalPrice || 0, {
                                        formal: false,
                                        floatingPoint: 0
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className={finalSellPrice >= modalPrice ? 'text-green-500' : 'text-red-500'}>
                            {finalSellPrice >= modalPrice ? "+" : "-"} {toRupiah(finalSellPrice - modalPrice, {
                                formal: false,
                                floatingPoint: 0
                            })}
                        </div>
                    </div>
                </Dialog>

                <div className="card p-fluid">
                    <h2 align="center">Marketplace Fee Calculator</h2>
                    <HargaModalCard onChangeValue={(x) => setModalPrice(x)} />

                    <Panel header="Harga Jual" className="my-3">
                        <TabMenu model={marketplaces}/>

                        <div className="grid mt-4">
                            <div className="col-12 md:col-6">
                                <label className="font-bold block mb-2">Harga Jual</label>
                                <div className="p-inputgroup flex-1">
                                    <span className="p-inputgroup-addon">Rp</span>
                                    <InputNumber value={sellPrice} onChange={x => setSellPrice(x.value)} locale="id-ID"/>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <label className="font-bold block mb-2">Diskon</label>
                                <div className="p-inputgroup flex-1">
                                    <Dropdown options={[
                                        {name: 'Persentase', code: 'percentage'},
                                        {name: 'Nominal', code: 'nominal'},
                                    ]}
                                              optionValue="code"
                                              value={discountType}
                                              onChange={x => {
                                                  setDiscountType(x.value)
                                                  setDiscountValue(0)
                                              }}
                                              optionLabel="name"
                                              className="w-10rem"/>
                                    {
                                        discountType === 'nominal' && (
                                            <span className="p-inputgroup-addon">Rp</span>
                                        )
                                    }
                                    <InputNumber value={discountValue} max={discountType === 'percentage' ? 100 : Number.MAX_VALUE}
                                                 locale="id-ID" onChange={(x) => setDiscountValue(x.value)} />
                                    {
                                        discountType === 'percentage' && (
                                            <span className="p-inputgroup-addon">%</span>
                                        )
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mt-3">
                            <label className="font-bold block mb-2">Tipe Penjual</label>
                            <Dropdown value={sellerType} onChange={(x) => setSellerType(x.value)} options={[
                                {name: 'Non-Star dengan pesanan terselesaikan <= 50', id: 0},
                                {name: 'Non-Star dengan total pesanan > 50', id: 1},
                                {name: 'Star/Star+', id: 2},
                                {name: 'Shopee Mall', id: 3},
                            ]}
                                      optionValue="id"
                                      optionLabel="name"
                                      placeholder="Tipe Penjual" className="w-full"/>
                        </div>

                        <div className="mt-4">
                            <label className="font-bold block mb-2">Kategori Produk</label>
                            <Dropdown value={category} onChange={(x) => setCategory(x.value)} options={[
                                {name: 'Kategori A', code: 'A'},
                                {name: 'Kategori B', code: 'B'},
                                {name: 'Kategori C', code: 'C'},
                                {name: 'Kategori D', code: 'D'},
                            ]}
                                      optionValue="code"
                                      optionLabel="name"
                                      placeholder="Kategori Produk" className="w-full"/>
                        </div>

                        <div className="mt-4">
                            <label className="font-bold block mb-1">Layanan Tambahan</label>
                            <div className="gap-3">
                                <div className="flex align-items-center">
                                    <Checkbox inputId="gratis-ongkir-extra" name="gratis-ongkir-extra"
                                              value="gratis-ongkir-extra" checked={useOngkirXtra} onChange={x => setUseOngkirXtra(x.checked)} />
                                    <label htmlFor="gratis-ongkir-extra" className="ml-2">Gratis Ongkir XTRA</label>

                                    <a href="https://seller.shopee.co.id/edu/article/3479" target="_blank" className="text-primary m-2">
                                        <Tooltip target=".gratis-ongkir-xtra" />
                                        <i className="pi pi-question-circle gratis-ongkir-xtra" data-pr-tooltip="Dikenakan fee 4% max 10.000"
                                           data-pr-position="right"></i>
                                    </a>
                                </div>

                                <div className="flex align-items-center">
                                    <Checkbox inputId="cashback-extra" name="cashback-extra"
                                              value="cashback-extra" checked={useCBXtra}
                                              onChange={x => setUseCBXtra(x.checked)}/>
                                    <label htmlFor="cashback-extra" className="ml-2">Cashback XTRA</label>

                                    <a href="https://seller.shopee.co.id/edu/article/7216" target="_blank"
                                       className="text-primary m-2">
                                        <Tooltip target=".gratis-ongkir-xtra"/>
                                        <i className="pi pi-question-circle gratis-ongkir-xtra"
                                           data-pr-tooltip="Dikenakan fee 1.4% max 10.000"
                                           data-pr-position="right"></i>
                                    </a>
                                </div>

                                <div className="flex align-items-center">
                                    <Checkbox inputId="asuransi" name="asuransi"
                                              value="asuransi" checked={useAsuransi}
                                              onChange={x => setUseAsuransi(x.checked)}/>
                                    <label htmlFor="asuransi" className="ml-2">Asuransi</label>

                                    <a href="https://seller.shopee.co.id/edu/article/17345" target="_blank"
                                       className="text-primary m-2">
                                        <Tooltip target=".gratis-ongkir-xtra"/>
                                        <i className="pi pi-question-circle gratis-ongkir-xtra"
                                           data-pr-tooltip="Dikenakan fee 0.5% tanpa batasan"
                                           data-pr-position="right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <label className="font-bold block mb-2">Voucher</label>
                            <div className="gap-3">
                                <div className="flex align-items-center">
                                    <Checkbox inputId="use-voucher" name="use-voucher"
                                              onChange={(x) => setUseVoucher(x.checked)}
                                              checked={useVoucher}/>
                                    <label htmlFor="use-voucher" className="ml-2">Potongan voucher (ditanggung penjual)</label>
                                </div>
                            </div>
                        </div>

                        {
                            useVoucher && (
                                <>
                                    <div className="mt-4">
                                        <label className="font-bold block mb-2">Tipe Voucher</label>
                                    </div>

                                    <div className="grid align-items-center">
                                        <div className="col-12 md:col-2">
                                            <label className="">Tipe Voucher</label>
                                        </div>
                                        <div className="col-12 md:col-10">
                                            <div className="p-inputgroup flex-1">
                                                <Dropdown options={[
                                                    {name: 'Persentase', code: 'percentage'},
                                                    {name: 'Nominal', code: 'nominal'},
                                                ]}
                                                          optionValue="code"
                                                          value={voucherType}
                                                          optionLabel="name"
                                                          onChange={x => {
                                                              setVoucherType(x.value)
                                                              setVoucherValue(0)
                                                          }}
                                                          className="w-10rem border-right-none"/>
                                                <InputNumber value={voucherValue} onChange={(x) => setVoucherValue(x.value)} locale="id-ID"/>

                                                {
                                                    voucherType === 'percentage' && (
                                                        <span className="p-inputgroup-addon">%</span>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        voucherType === 'percentage' && (
                                            <div className="grid align-items-center mt-2">
                                                <div className="col-12 md:col-2">
                                                    <label className="">Maksimum Potongan</label>
                                                </div>
                                                <div className="col-12 md:col-10">
                                                    <div className="flex flex-wrap">
                                                        <div className="flex align-items-center">
                                                            <RadioButton checked={maxVoucherOffType === '1'}
                                                                         onChange={(x) => setMaxVoucherOffType(x.value)}
                                                                         inputId="set-max-discount-voucher" name="max-voucher-off" value="1"
                                                            />
                                                            <label htmlFor="set-max-discount-voucher" className="ml-2">Atur Diskon Maksimum</label>
                                                        </div>

                                                        <div className="flex align-items-center mt-2 ml-0 md:mt-0 md:ml-3">
                                                            <RadioButton checked={maxVoucherOffType === '0'}
                                                                         onChange={(x) => setMaxVoucherOffType(x.value)}
                                                                         inputId="unlimitied-max-discount-voucher" name="max-voucher-off" value="0"
                                                            />
                                                            <label htmlFor="unlimitied-max-discount-voucher" className="ml-2">Tidak Terbatas</label>
                                                        </div>

                                                        {
                                                            maxVoucherOffType === '1' && (
                                                                <div className="w-full mt-2">
                                                                    <div className="p-inputgroup flex-1">
                                                                        <span className="p-inputgroup-addon">Rp</span>
                                                                        <InputNumber value={maxVoucherOff} onChange={(x) => setMaxVoucherOff(x.value)} locale="id-ID"/>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }

                                    <div className="grid align-items-center mt-2">
                                        <div className="col-12 md:col-2">
                                            <label className="">Minimum Pembelian</label>
                                        </div>
                                        <div className="col-12 md:col-10">
                                            <div className="p-inputgroup flex-1">
                                                <span className="p-inputgroup-addon">Rp</span>
                                                <InputNumber locale="id-ID" value={minVoucherUsage} onChange={(x) => setMinVoucherUsage(x.value)} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </Panel>

                    <Button label="Hitung" disabled={!sellPrice || !modalPrice} onClick={calculate} />
                </div>
            </main>
        </PrimeReactProvider>
    )
}


export default App;
